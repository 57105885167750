import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: "#4991D7",
                secondary: "#8AB8E3",
                accent: "#82B1FF",
                error: "#dc3545",
                info: "#005589",
                success: "#4CAF50",
                warning: "#FFC107"
            }
        }
    },
    typography: {
        fontFamily: 'DM Sans, sans-serif',
    },
});
