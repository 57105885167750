<template>
    <v-container class="StepData">
      <v-row class="">
        <v-col cols="12" sm="12">
          <v-form v-model="valid">
            <v-row class="mx-0 m-form">
              
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="data.Brand"
                  :label="labelText"
                  dense
                  color="primary"
                  hide-details
                ></v-text-field>
              </v-col>
              
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="data.line"
                  :label="lineText"
                  dense
                  color="primary"
                  hide-details
                ></v-text-field>
              </v-col>
              
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="data.year"
                  label="Año"
                  dense
                  color="primary"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="data.checkbox"
                  label="¿El vehículo es 0 kilometros?"
                  class="mt-0"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>

  export default {
    name: "StepData",
    props: { 
      Car: Object,
      idNumber: String

    },
    data() {
      return {
        valid: false,
        data: {
          Brand: '',
          line: '',
          year: '',
          checkbox: false,
        }
      };
    },
    created() {},
    mounted() {},
    computed: {
      labelText() {
        if (this.idNumber === 'cart') {
          return 'Marca del Auto';
        } else if (this.idNumber === 'Moto') {
          return 'Marca de Moto';
        }
        return 'Marca'; 
      },
      lineText() {
        if (this.idNumber === 'cart') {
          return 'Línea del Vehículo';
        } else if (this.idNumber === 'Moto') {
          return 'Línea de la Moto';
        }
        return 'Marca'; 
      }
    },
  };
  </script>
  
  <style lang="scss" >
  </style>