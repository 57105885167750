<template>
    <v-row class="mt-3">
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <img alt="Vue logo" src="https://ikonoseguros.co/wp-content/uploads/2024/05/auto.png" width="520" height="460">
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-stepper v-model="e1" outlined rounded class="auto-steper">
                <v-stepper-header>
                    <v-stepper-step
                        :complete="e1 > 1"
                        step="1"
                    >
                        Datos del Auto
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="e1 > 2"
                        step="2"
                    >
                        Datos personales
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        Cotización
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card
                          class=""
                          elevation="0"
                        >
                            <StepData :Car.sync="car" :idNumber="'cart'"/>
                        </v-card>

                        <div class="d-flex justify-end">
                            <v-btn color="primary" @click="e1 = 2" class="ma-2" width="220">
                                Sigueinte
                            </v-btn>
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card
                            elevation="0"
                        >
                            <UserInfo/>
                        </v-card>

                        <div class="d-flex justify-end">
                            <v-btn 
                               color="primary" 
                               outlined
                               @click="e1 = 1"
                               class="ma-2" 
                            >
                                Atras
                            </v-btn>

                            <v-btn
                               color="primary"
                               @click="e1 = 3"
                               class="ma-2" 
                            >
                                Sigueinte
                            </v-btn>
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-card
                           elevation="0"
                        >
                        </v-card>
                        <div class="d-flex justify-end">
                            <v-btn 
                               color="primary" 
                               outlined
                               @click="e1 = 2"
                               class="ma-2" 
                            >
                                Atras
                            </v-btn>

                            <v-btn
                               color="primary"
                               class="ma-2"
                               @click="cotizar()"
                            >
                                Continue
                            </v-btn>
                        </div>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-col>
    </v-row>
  </template>
  
  <script>
  import StepData from '@/components/formAutos/StepData.vue';
  import UserInfo from '@/components/formAutos/UserInfo.vue';
  export default {
    name: 'AutoInsuranceForm',
    components: { StepData,UserInfo},
    data () {
        return {
            e1: 1,
            car: {
                Brand: '',
            }
        }
    },
    methods:{
        cotizar(){
            window.location.reload()
        }
    }
  }
</script>
  
<style lang="sass" >
.v-stepper__header
    box-shadow: none
.v-stepper.auto-steper
    border: none !important
    background: transparent !important
    .v-stepper__items
        border: thin solid rgba(0, 0, 0, 0.12)
        border-radius: 6px
        background: #FFFFFF
</style>