<template>
    <v-container class="StepData">
      <v-row class="">
        <v-col cols="12" sm="12">
            <v-form v-model="valid">
                <v-row class="mx-0 m-form">
                    <v-col cols="12" sm="12">
                        <v-text-field
                            v-model="name"
                            label="Nombre"
                            dense
                            color="primary"
                            hide-details
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                        <v-text-field
                            v-model="phone"
                            label="Celular"
                            dense
                            color="primary"
                            hide-details
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                        <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            label="Correo electrónico"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "UserInfo",
    props: { Car: Object },
    data() {
      return {
        valid: false,
        name: '',
        phone: '',
        email: '',
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
      };
    },
    created() {},
    mounted() {},
    computed: {
      
    },
  };
  </script>
  
  <style lang="scss" >
  </style>