<template>
  <v-app>
    <v-main>
      <section id="insurance-policy-form" class="pb-5">
        <InsurancePolicyForm/>
      </section>
      
      <section id="steps-users" class="pb-12 py-2" style="background-color: #FFFFFF;">
        <StepsUsers />
      </section>
      
      <section id="allied-companies" class="py-12" style="background-color: #F4F6F9;">
        <AlliedCompanies/>
      </section>

      <section id="warranty-section" class="py-12" style="background-color: #FFFFFF;">
        <WarrantySection />
      </section>


      <section id="testimonials-section" class="py-12" style="background-color: #F4F6F9;">
        <TestimonialsSection/>
      </section>
    </v-main>
  </v-app>
</template>

<script>
import InsurancePolicyForm from './components/InsurancePolicyForm';
import AlliedCompanies from './components/AlliedCompanies';
import TestimonialsSection from './components/TestimonialsSection';
import StepsUsers from './components/StepsUsers';
import WarrantySection from './components/WarrantySection';

export default {
  name: 'App',

  components: {
    InsurancePolicyForm, AlliedCompanies,TestimonialsSection, StepsUsers, WarrantySection
  },

  data: () => ({
    //
  }),
};
</script>

<style>
  .v-application {
    font-family: 'DM Sans', sans-serif !important;
  }
</style>
