<template>
    <v-container class="warranty">
        <v-row class="mt-3">
            <v-col cols="12" class="text-center">
                <h2 >
                    Garantía
                </h2>
                <span>
                    
                    Nuestro compromiso es brindarte tranquilidad, asesoramiento sin costo y alternativas de pago <br>flexibles para garantizarte una experiencia satisfactoria.
                    Incluir Bullet similares a estos que respalden la garantía.
                </span>
            </v-col>
        </v-row>
        <v-row class="mt-10" justify="center" >
            <v-col cols="12" sm="8">
                <v-card 
                v-for="(item, index) in items" :key="index" 
                    width="100%" 
                    class="mx-3 my-3 pa-2 rounded-xxl"
                    outlined
                    style="border: 1px solid #C1CEDB"
                >
                    <v-card-text>
                        <div class="d-flex align-center">
                            <v-icon size="40" color="#191769" class="mr-5">{{ item.icon }}</v-icon>
                            <div class=" mr-2" :class="item.id != 2 ? 'font-weight-bold ' : ''" style="font-size: 22px; ">{{ item.title }}</div>
                            <span :class="item.id == 2 ? 'font-weight-bold' : 'font-weight-medium'" style="font-size: 22px;">{{ item.description }}</span>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
  
  <script>

  export default {
    name: 'StepsUsers',
    data () {
        return {
            items: [
                {
                    title: 'Paga directamente',
                    description: 'con la aseguradora.',
                    id: 1,
                    icon: 'mdi-account-cash-outline'
                },
                {
                    title: 'Te asesoramos',
                    description: 'gratis.',
                    id: 2,
                    icon: 'mdi-headset'
                },
                {
                    title: 'Tramitamos por ti ',
                    description: 'sin ningún costo.',
                    id: 3,
                    icon: 'mdi-clipboard-check-outline'
                },
                {
                    title: 'Paga en cuotas',
                    description: 'o de contado.',
                    id: 4,
                    icon: 'mdi-piggy-bank-outline'
                }
            ]
        }
    },
    methods:{
        
    }
  }
</script>
  
<style lang="sass" >


</style>