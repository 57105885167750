<template>
    <v-container class="users-steps">
        <v-row class="mt-3">
            <v-col cols="12" class="text-center">
                <h2>
                    <span style="color: #DB0000">4</span>
                    Pasos para adquirir tu seguro <br>de vehículo (motos, carros) en Medellín:
                </h2>
            </v-col>
        </v-row>
        <v-row class="justify-center mt-6" >
            <v-col cols="12" sm="12" class="d-flex justify-center" style="flex-wrap: wrap;">
                <v-card 
                v-for="(item, index) in items" :key="index" 
                    width="310" 
                    class="mx-3 my-3 pa-4 rounded-lg"
                    outlined
                    style="border: 1px solid #C1CEDB"
                >
                    <v-card-text>
                        <div class="">
                            <sapan class="d-flex justify-center align-center number-id ">
                                {{ item.id }}
                            </sapan>
                        </div>
                        <div class="d-flex justify-center mt-3" >
                            <v-icon size="60" color="#191769">{{ item.icon }}</v-icon>
                        </div>
                        <div class="d-flex justify-center align-center flex-column mt-10">
                            <div class="text-information">{{ item.title }}</div>
                            <div class="text-information">{{ item.description }}</div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-3">
            <v-col cols="12" class="text-center">
                <h2>
                    Somos intermediarios de seguros
                </h2>
                <span>
                    Tu aliado en seguros personalizados. Somos un intermediario de seguros en Medellín y Antioquia, <br> creando planes personalizados para proteger lo que más valoras
                </span>
            </v-col>
        </v-row>
    </v-container>
</template>
  
  <script>

  export default {
    name: 'StepsUsers',
    data () {
        return {
            items: [
                {
                    title: 'Obtén tu',
                    description: 'cotización comparativa',
                    id: 1,
                    icon: 'mdi-file-document-outline'
                },
                {
                    title: 'Asesórate gratis',
                    description: 'y elige la aseguradora',
                    id: 2,
                    icon: 'mdi-headset'
                },
                {
                    title: 'Recibe y verifica',
                    description: 'tu póliza',
                    id: 3,
                    icon: 'mdi-clipboard-check-outline'
                },
                {
                    title: 'Paga directamente',
                    description: 'en la aseguradora',
                    id: 4,
                    icon: 'mdi-account-cash-outline'
                }
            ]
        }
    },
    methods:{
        
    }
  }
</script>
  
<style lang="sass" >
.number-id 
    font-size: 40px
    font-weight: bold
    background: #d7d6ff
    color: #191769
    border-radius: 50%
    width: 55px
    height: 55px
    margin: -5px 0px 0px -5px
.text-information
    font-size: 20px
    font-weight: 500

</style>