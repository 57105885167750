<template>
  <v-container>
    <v-row justify="center" class="mt-5">
      <v-col cols="12" sm="12" class="text-center">
        <h1 class="mb-3">DISFRUTA LA VIDA CON SEGURIDAD Y TRANQUILIDAD</h1>
        <p>Nuestros seguros te ofrecen la protección que necesitas para cuidar a tu familia, tu hogar y tus posesiones más preciadas.</p>
      </v-col>
      
      <v-col cols="12" sm="12" md="12" lg="10" xl="10">
        <div class="d-flex">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="primary"
            centered
            icons-and-text
            height="130"
            hide-slider
            grow
          >
            <v-tab v-for="item in items" :key="item.id" class="pa-10 mx-3">
              <div>{{ item.text }}</div>
              <v-icon size="60">{{ item.icon}}</v-icon>
            </v-tab>
          </v-tabs>
        </div>
      </v-col>
    </v-row>
    
    <v-row justify="center">
      <v-col cols="12" sm="12" md="12" lg="10" xl="10">
        
        <section v-if="tab == 0" id="auto-form" class="pb-10">
          <AutoInsuranceForm/>
        </section>
        
        <section v-if="tab == 1" id="motor-form" class="pb-10">
          <v-container class="">
            <MotorcycleInsuranceForm :numbertab="tab"/>
          </v-container>
        </section>
        
        
        <section v-if="tab == 2" id="homepolicyform" class="pb-10">
          <v-container class="">
            <HomePolicyForm @continue-action="openDialog" />
          </v-container>
        </section>
        
        
        <section v-if="tab == 3" id="commercial" class="pb-10">
          <v-container class="">
            <CommercialPolicyForm @continue-action="openDialog"/>
          </v-container>
        </section> 

        <section v-if="tab == 4" id="travel" class="pb-10">
          <v-container class="">
            <TravelPolicyForm @continue-action="openDialog"/>
          </v-container>
        </section> 

        <section v-if="tab == 5" id="health" class="pb-10">
          <v-container class="">
            <HealthPolicyForm @continue-action="openDialog"/>
          </v-container>
        </section> 
      </v-col>
    </v-row>
    <QuoteDialog v-if="showDialog" @close-dialog="closeDialog"/>
  </v-container>
</template>

<script>
  import AutoInsuranceForm from './AutoInsuranceForm.vue';
  import MotorcycleInsuranceForm from './MotorcycleInsuranceForm.vue';
  import HomePolicyForm from './HomePolicyForm.vue';
  import QuoteDialog from './QuoteDialog.vue';
  import CommercialPolicyForm from './CommercialPolicyForm.vue';
  import TravelPolicyForm from './TravelPolicyForm.vue';
  import HealthPolicyForm from './HealthPolicyForm.vue';
  export default {
    name: 'InsurancePolicyForm',
    components: {
      AutoInsuranceForm, MotorcycleInsuranceForm, HomePolicyForm, QuoteDialog, CommercialPolicyForm, TravelPolicyForm, HealthPolicyForm
  },

    data: () => ({
      tab: null,
      items: [
        {
          text: 'Autos', 
          icon: 'mdi-car-back',
          id: 1
        },
        {
          text: 'Motos', 
          icon: 'mdi-motorbike',
          id: 2
        },
        {
          text: 'Hogar',
          icon: 'mdi-home',
          id: 3
        },
        {
          text: 'Empresas',
          icon: 'mdi-storefront-outline',
          id: 4
        },
        {
          text: 'Viajes',
          icon: 'mdi-airplane-takeoff',
          id: 5
        },
        {
          text: 'Vida y Salud',
          icon: 'mdi-hand-heart-outline',
          id: 6
        },    
      ],
      showDialog: false
    }),
    methods:{
      openDialog(){
        this.showDialog = true
      },
      closeDialog(){
        this.showDialog = false
      }
    }
  }
</script>
<style lang="sass" >
.v-tab.v-tab
  background: #EEF3F6
  border-radius: 6px
  border: 1px solid #C1CEDB
  color: #4991D7 !important
  .v-icon.v-icon
    color: #8AB8E3 !important
  &.v-tab--active 
    background: #4991D7
    color: #ffffff !important
    .v-icon.v-icon
      color: #B6D3EF
.v-slide-group__content
  justify-content: center
</style>