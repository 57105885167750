<template>
    <div>
        <v-container class="testimonials-section">
            <v-row class="">
                <v-col cols="12" sm="12" class="text-center">
                    <h2 class="mb-3">¿Qué opinan nuestros clientes?</h2>
                    <p></p>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="testimonials-section">
            <v-row class="justify-center" >
                <v-col cols="12" sm="12" class="d-flex justify-center" style="flex-wrap: wrap;">
                    <v-card 
                    v-for="(item, index) in items" :key="index" 
                        width="355" 
                        class="mx-3 my-3"
                        outlined
                        style="border: 1px solid #C1CEDB"
                    >
                        <v-card-title>
                            <v-list style="background: transparent !important;">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <img
                                            :src="item.img"
                                            :alt="item.name"
                                        >
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                                        <v-list-item-subtitle class="primary--text">{{ item.email }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card-title>
                        
                        <v-card-text>
                            {{ item.text }}
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>  
</template>
  
<script>
    export default {
        name: "TestimonialsSection",
        data() {
            return {
                items: [
                    {
                        name: 'Nelu',
                        img: 'https://cdn.vuetifyjs.com/images/john.jpg',
                        email: '@nelu stones',
                        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown..."
                    },
                    {
                        name: 'Bente',
                        img: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460',
                        email: '@bente janes',
                        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown..."
                    },
                    {
                        name: 'Nelu',
                        img: 'https://cdn.vuetifyjs.com/images/john.jpg',
                        email: '@nelu stones',
                        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown..."
                    },
                    {
                        name: 'Bente',
                        img: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460',
                        email: '@bente janes',
                        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown..."
                    },
                    {
                        name: 'Nelu',
                        img: 'https://cdn.vuetifyjs.com/images/john.jpg',
                        email: '@nelu stones',
                        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown..."
                    },
                    {
                        name: 'Bente',
                        img: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460',
                        email: '@bente janes',
                        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown..."
                    }
                ]
            };
        },
        created() {},
        mounted() {},
        computed: {},
    };
</script>
  
<style lang="scss" >
</style>