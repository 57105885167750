<template>
    <v-dialog
          transition="dialog-top-transition"
          max-width="700"
          v-model="dialog"
        >
            <v-card clas>
                <v-toolbar dark color="primary text-h5 font-weight-bold justify-center d-flex">Cotización</v-toolbar>
                
                <v-card-text class="pa-12">
                    <div class="text-center">Contaras con la asesoría y acompañamiento de uno de nuestros ejecutivos. <br> Nos estaremos contactando contigo lo antes posible</div>
                    <div class="d-flex justify-center pt-5"><v-btn color="primary" outlined large @click="close">Aceptar</v-btn></div>
                </v-card-text>
                
          </v-card>
    </v-dialog>
</template>
  
<script>
  
    export default {
        name: 'QuoteDialog',
        data () {
            return {
                dialog: true
            }
        },
        methods: {
            close(){
                this.$emit('close-dialog')
                this.dialog = false
                window.location.reload()
            }
        }
    }
</script>
  
<style lang="sass" >
.w-100
    width: 100% !important
</style>